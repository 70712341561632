.games-container{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    flex-wrap: wrap;
}

.game-container{
    margin: 1rem;
    padding: 0.5rem;
    border: 2px solid;
    border-color: var(--color-dark);
}

