.footer-container{
    margin:0;
    padding: 0rem 1rem 0rem 1rem;
    height: 5rem;
    background-color: var(--color-dark);
    display: flex;
    align-items: center;
  }
  
  .footer-container p {
    margin: 0;
  }