@import url(https://fonts.googleapis.com/css2?family=Montserrat&display=swap);
:root{
  --color-base:#4f6781;
  --color-dark: #031B35;
  --color-light: #B5CDE7;
  --color-white: #E6E6E6;  ;
}


body {
  background-color: #031B35;
  background-color: var(--color-dark);
  color: #E6E6E6;
  color: var(--color-white);
  text-align: center;
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a{
  color: #B5CDE7;
  color: var(--color-light);
  text-decoration: none;
}

a:hover{
  text-decoration: underline;
}

.page-container{
  background-color: var(--color-base);
  margin: 0;
  min-height: 65vh;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.header h1{
  color: var(--color-light);
}

.container {
  font-size: 1.3rem;
  color: var(--color-white);
  min-width: 50ex;
}

.manatee-logo{
    fill: var(--background-color-darken);
    transform-origin: center center;
    -webkit-transform-origin: center center;
    -webkit-animation:spin infinite 10s linear;
            animation:spin infinite 10s linear;
}

.manatee-logo:hover, .stop-animation{
    -webkit-animation-play-state: paused;
            animation-play-state: paused;
}
@-webkit-keyframes spin {
    from { -webkit-transform: rotate(0deg); }
    to { -webkit-transform: rotate(360deg); }
}
@keyframes spin {
    from {-webkit-transform:rotate(0deg);transform:rotate(0deg);}
    to {-webkit-transform:rotate(360deg);transform:rotate(360deg);}
}


.overflow-visible{
    overflow:visible;
}

.display-inline{
    display:inline;
}

.fill-primary {
    fill: var(--color-dark);
}

.fill-secondary{
    fill: var(--color-light);
}

.fill-logo-manatee-fill, .fill-logo-fill {
    fill: var(--color-base);
    stroke: #000000;
    stroke-width:0;
    stop-color:#000000
}

.logo-electron-path{
    fill: var(--color-light);
    stroke-width:1.25024
}

.logo-electron-path:hover{
    fill: var(--color-white);
}

.logo-electron-fill{
    fill:#ffffff;
    stroke:#241f1c;
    stroke-width:0;
    stop-color:#000000
}
.about-text{
    padding: 2rem;
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    font-size: 1.2rem;
}

.games-container{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    flex-wrap: wrap;
}

.game-container{
    margin: 1rem;
    padding: 0.5rem;
    border: 2px solid;
    border-color: var(--color-dark);
}


.footer-container{
    margin:0;
    padding: 0rem 1rem 0rem 1rem;
    height: 5rem;
    background-color: var(--color-dark);
    display: flex;
    align-items: center;
  }
  
  .footer-container p {
    margin: 0;
  }
.navbar-container{
    height: 5rem;
    width: 100wv;
    background-color: var(--color-dark);
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.navbar-container a{
    padding: 0 1rem 0 1rem;
    font-size: 1.2rem;
}

.navlink-selected{
    font-weight: bold;
    color: var(--primary-color-darken);
}
