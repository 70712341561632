
.page-container{
  background-color: var(--color-base);
  margin: 0;
  min-height: 65vh;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
}